body, html {
    padding: 0;
    margin: 0;
    background-color: #000;
    color: #fff;
    font-family: 'Webfont';
}
h1,h2,h3,h4{
    hyphens: none;
    line-height: 1.1;
    font-family: 'Webfont2';
    letter-spacing: -1.3px;
}
a{
    color:#fff;
}
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=d1e06ff8-a632-47b2-a0c7-70becb546524&fontids=5225798,5350357");
@font-face {
    font-family: 'Webfont';
    src: url('./assets/fonts/4a2626c5-30e7-4564-9b08-a9702d4b442c.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Webfont2';
    src: url('./assets/fonts/309da216-437b-473f-b4f5-419c9e6787ff.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
#root{
    max-width: 750px;
    margin: 0 auto;
}
.bm-burger-button {
    position: fixed;
    width: 3rem;
    height: 2.5rem;
    left: 80%;
    top: 5%;
}
.bm-burger-bars {
    background: #fff;
}
.bm-menu-wrap{
    max-width: 500px;
}
.bm-menu {
    background: #000;
    padding: 5rem 1.5em 0;
    font-size: 1.15em;
}
.bm-item-list{
    height: 90% !important;
}
.bm-overlay {
    background: rgba(255, 255, 255, 0.3);
}
.bm-cross-button{
    width: 2rem !important;
    height: auto !important;
    right: 10% !important;
    top: 3% !important;
}
.bm-cross{
    background: none;
}
.fadeInAnimation {
    animation: fadeAnimation 1s forwards;
}
@keyframes fadeAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
}